export const applicantGroup = {
  title: "Contact",
  default: true,
  weight: 0,
  components: {
    organisation: {
      title: "Organisation",
      key: "sc_orgname",
      icon: "fab fa-users",
      schema: {
        label: "Organisation",
        type: "textfield",
        key: "scOrgname",
        input: true,
        validate: {
          maxLength: 250,
          required: true,
        },
        tooltip: "Name of your organisation",
        description: "Name of your organisation",
      },
    },
    street: {
      title: "Street",
      key: "sc_orgstreet",
      icon: "fab fa-map-marker",
      schema: {
        label: "Straße",
        type: "textfield",
        key: "scOrgstreet",
        input: true,
        validate: {
          maxLength: 100,
          required: true,
        },
        tooltip: "Street of your organisation",
        description: "Street of your organisation",
      },
    },
    houseNo: {
      title: "Houseno",
      key: "sc_orghouseno",
      icon: "fab fa-map-marker",
      schema: {
        label: "Hausnummer",
        type: "textfield",
        key: "scOrghouseno",
        input: true,
        validate: {
          maxLength: 10,
          required: true,
        },
        tooltip: "House number of your organisation",
        description: "House number of your organisation",
      },
    },
    zipCode: {
      title: "ZIP code",
      key: "sc_orgzipcode",
      icon: "fab fa-map-marker",
      schema: {
        label: "PLZ",
        type: "textfield",
        key: "scOrgzipcode",
        input: true,
        validate: {
          maxLength: 50,
          required: true,
        },
        tooltip: "Zip code of your organisation",
        description: "Zip code of your organisation",
        attributes: {
          style: "width:25%;",
        },
      },
    },
    city: {
      title: "City",
      key: "sc_orgcity",
      icon: "fab fa-map-marker",
      schema: {
        label: "Ort",
        type: "textfield",
        key: "scOrgcity",
        input: true,
        validate: {
          maxLength: 100,
          required: true,
        },
        tooltip: "City of your organisation",
        description: "City of your organisation",
      },
    },
    salutation: {
      title: "Salutation",
      key: "sc_salutation",
      icon: "terminal",
      schema: {
        label: "Anrede",
        type: "textfield",
        key: "scSalutation",
        input: true,
        validate: {
          maxLength: 250,
          required: true,
        },
        tooltip: "Salutation of the contact person",
        description: "Salutation of the contact person",
        attributes: {
          style: "width:25%;",
        },
      },
    },
    firstName: {
      title: "First Name",
      key: "sc_firstname",
      icon: "terminal",
      schema: {
        label: "Vorname",
        type: "textfield",
        key: "scFirstname",
        input: true,
        validate: {
          maxLength: 250,
          required: true,
        },
        tooltip: "First name of the contact person",
        description: "First name of the contact person",
      },
    },
    lastName: {
      title: "Last Name",
      key: "sc_lastname",
      icon: "terminal",
      schema: {
        label: "Nachname",
        type: "textfield",
        key: "scLastname",
        input: true,
        validate: {
          maxLength: 250,
          required: true,
        },
        tooltip: "Last name of the contact person",
        description: "Last name of the contact person",
      },
    },
    titleCustom: {
      title: "Title",
      icon: "terminal",
      schema: {
        label: "Columns",
        columns: [
          {
            components: [
              {
                label: "Titel",
                widget: "html5",
                description: "Titel des Ansprechpartners",
                tooltip: "Titel des Ansprechpartners",
                tableView: true,
                data: {
                  values: [
                    {
                      label: "Dr.",
                      value: "Dr.",
                    },
                    {
                      label: "Prof.",
                      value: "Prof.",
                    },
                    {
                      label: "Titel eingeben",
                      value: "titelEingeben",
                    },
                  ],
                },
                key: "titleChoice",
                type: "select",
                input: true,
              },
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0,
            size: "md",
            currentWidth: 6,
          },
          {
            components: [
              {
                label: "Titel eingeben",
                tableView: true,
                validate: {
                  maxLength: 100,
                },
                key: "customTitle",
                conditional: {
                  show: true,
                  when: "titleChoice",
                  eq: "titelEingeben",
                },
                type: "textfield",
                input: true,
              },
              {
                label: "titel",
                key: "scTitle",
                logic: [
                  {
                    name: "logic",
                    trigger: {
                      type: "javascript",
                      javascript:
                        "result = data['titleChoice'] === 'Dr.' || data['titleChoice'] === \"Prof.\" || data['titleChoice'] === 'titelEingeben';",
                    },
                    actions: [
                      {
                        name: "action ",
                        type: "customAction",
                        customAction:
                          "if (data['titleChoice'] === 'Dr.' || data['titleChoice'] === \"Prof.\"){\n  value = data['titleChoice']\n} else if (data['titleChoice'] === 'titelEingeben'){\n  value = data['customTitle']\n}",
                      },
                    ],
                  },
                ],
                type: "hidden",
                input: true,
                tableView: false,
              },
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0,
            size: "md",
            currentWidth: 6,
          },
        ],
        key: "columns",
        type: "columns",
        input: false,
        tableView: false,
      },
    },
    department: {
      title: "Abteilung",
      key: "scDepartment",
      icon: "terminal",
      schema: {
        label: "Abteilung",
        type: "textfield",
        key: "scDepartment",
        input: true,
        validate: {
          maxLength: 100,
          required: false,
        },
        tooltip: "Department in your organiation",
        description: "Department in your organiation",
      },
    },
    position: {
      title: "Position",
      key: "scPosition",
      icon: "terminal",
      schema: {
        label: "Position",
        type: "textfield",
        key: "scPosition",
        input: true,
        validate: {
          maxLength: 100,
          required: false,
        },
        tooltip: "Your job position",
        description: "Your job position",
      },
    },
    customEmail: {
      title: "E-Mail Person",
      key: "scEmail",
      icon: "fab fa-envelope",
      schema: {
        label: "E-Mail",
        type: "email",
        key: "scEmail",
        input: true,
        validate: {
          maxLength: 100,
          required: true,
        },
        tooltip: "E-Mail of the contact person",
        description: "E-Mail of the contact person",
      },
    },
    emailOrg: {
      title: "E-Mail Org",
      key: "scEmailOrg",
      icon: "fab fa-envelope",
      schema: {
        label: "E-Mail Organisation",
        type: "email",
        key: "scEmailOrg",
        input: true,
        validate: {
          maxLength: 100,
          required: true,
        },
        tooltip: "E-Mail of the organisation",
        description: "E-Mail of the organisation",
      },
    },
    phone: {
      title: "Phone Person",
      key: "sc_phone",
      icon: "fab fa-phone-square",
      schema: {
        label: "Telefon",
        type: "textfield",
        key: "scPhone",
        input: true,
        validate: {
          maxLength: 100,
          required: true,
        },
        tooltip: "Phone number of the contact person",
        description: "Phone number of the contact person",
      },
    },
    phoneOrg: {
      title: "Phone Org",
      key: "sc_phone_org",
      icon: "fab fa-phone-square",
      schema: {
        label: "Telefon Organisation",
        type: "textfield",
        key: "scPhoneOrg",
        input: true,
        validate: {
          maxLength: 100,
          required: true,
        },
        tooltip: "Phone number of the organisation",
        description: "Phone number of the organisation",
      },
    },
    mobilePhone: {
      title: "Mobile phone",
      key: "sc_phone",
      icon: "fab fa-phone-square",
      schema: {
        label: "Mobiltelefon",
        type: "textfield",
        key: "scMobile",
        input: true,
        validate: {
          maxLength: 100,
          required: true,
        },
        tooltip: "Mobile phone number of the contact person",
        description: "Mobile phone number of the contact person",
      },
    },
    iban: {
      title: "IBAN",
      key: "sc_iban",
      icon: "terminal",
      schema: {
        label: "IBAN",
        type: "textfield",
        key: "scIban",
        input: true,
        validate: {
          maxLength: 100,
          required: false,
          custom:
              'valid = input.toUpperCase().replace(/[^A-Z0-9]/g, "").match(/^(?:((?:IT|SM)\\d{2}[A-Z]{1}\\d{22})|(NL\\d{2}[A-Z]{4}\\d{10})|(LV\\d{2}[A-Z]{4}\\d{13})|((?:BG|GB|IE)\\d{2}[A-Z]{4}\\d{14})|(GI\\d{2}[A-Z]{4}\\d{15})|(RO\\d{2}[A-Z]{4}\\d{16})|(MT\\d{2}[A-Z]{4}\\d{23})|(NO\\d{13})|((?:DK|FI)\\d{16})|((?:SI)\\d{17})|((?:AT|EE|LU|LT)\\d{18})|((?:HR|LI|CH)\\d{19})|((?:DE|VA)\\d{20})|((?:AD|CZ|ES|MD|SK|SE)\\d{22})|(PT\\d{23})|((?:IS)\\d{24})|((?:BE)\\d{14})|((?:FR|MC|GR)\\d{25})|((?:PL|HU|CY)\\d{26}))$/) ? true : "IBAN ungültig"'
        },
        tooltip: "Bank account of your organisation",
        description: "Bank account of your organisation",
      },
    },
    accountOwner: {
      title: "Account Owner",
      key: "sc_accowner",
      icon: "terminal",
      schema: {
        label: "Kontoinhaber",
        type: "textfield",
        key: "scAccowner",
        input: true,
        validate: {
          maxLength: 100,
          required: false,
        },
        tooltip: "Account holder",
        description: "Account holder",
      },
    },
    homepage: {
      title: "Homepage",
      key: "scWebsite",
      icon: "fab fa-link",
      schema: {
        label: "Website",
        type: "textfield",
        key: "scWebsite",
        input: true,
        validate: {
          maxLength: 100,
          required: false,
        },
        tooltip: "Website of your organisation",
        description: "Website of your organisation",
      },
    },
    scDimension: {
      title: "Dimension",
      key: "scDimension",
      icon: "plus-square",
      schema: { 
        key: "scDimension",
        type: "selectboxes",
        label: "Dimension",
        input: true,
      },
    },
  },
};

export const projectGroup = {
  title: "Project",
  default: false,
  weight: 10,
  components: {
    projectName: {
      title: "Project Name",
      key: "sp_name",
      icon: "terminal",
      schema: {
        label: "Projektbezeichnung",
        type: "textfield",
        key: "spName",
        input: true,
        validate: {
          maxLength: 250,
          required: true,
        },
        tooltip: "Project name",
        description: "Project name",
      },
    },

    projectDescription: {
      title: "Project Description",
      key: "sp_description",
      icon: "terminal",
      schema: {
        label: "Kurzbeschreibung",
        type: "textarea",
        key: "spDescription",
        input: true,
        validate: {
          maxLength: 4000,
          required: true,
        },
        tooltip: "Project description",
        description: "Project description",
      },
    },
    projectStart: {
      title: "Project start",
      key: "sp_start",
      icon: "fab fa-calendar",
      schema: {
        label: "Projektbeginn",
        type: "datetime",
        enableTime: false,
        format: "dd.MM.yyyy",
        key: "spStart",
        input: true,
        validate: {
          required: true,
        },
        tooltip: "Project starts at",
        description: "Project starts at",
        customClass: "w-25",
      },
    },
    projectEnd: {
      title: "Project end",
      key: "sp_end",
      icon: "fab fa-calendar",
      schema: {
        label: "Projektende",
        type: "datetime",
        enableTime: false,
        format: "dd.MM.yyyy",
        key: "spEnd",
        input: true,
        validate: {
          required: false,
        },
        tooltip: "Project will be finished by",
        description: "Project will be finished by",
        customClass: "w-25",
      },
    },
    nonprofitProject: {
      title: "Nonprofit project",
      key: "sp_isnpo",
      icon: "terminal",
      schema: {
        label: "Gemeinnütziges Vorhaben",
        type: "radio",
        key: "spIsnpo",
        input: true,
        dataType: "string",
        validate: {
          required: true,
        },
        tooltip: "Is it a non-profit project?",
        description: "Is it a non-profit project?",
        values: [
          {
            label: "Ja",
            value: "true",
            shortcut: "",
          },
          {
            label: "Nein",
            value: "false",
            shortcut: "",
          },
        ],
      },
    },
    charitableObjective: {
      title: "Charitable objective",
      key: "sp_ktr_ao",
      icon: "terminal",
      schema: {
        label: "Zwecke nach AO",
        type: "select",
        key: "spKtrAo",
        input: true,
        validate: {
          required: false,
        },
        tooltip: "Charitable objective for the project",
        description: "Charitable objective for the project",
        conditional: {
          show: true,
          when: "spIsnpo",
          eq: "true",
          json: "",
        },
        data: {
          values: [
            {
              label: "Wissenschaft, Forschung",
              value: "Zweck_01",
            },
            {
              label: "Religion",
              value: "Zweck_02",
            },
            {
              label: "Gesundheitswesen",
              value: "Zweck_03",
            },
            {
              label: "Jugend- und Altenhilfe",
              value: "Zweck_04",
            },
            {
              label: "Kunst, Kultur",
              value: "Zweck_05",
            },
            {
              label: "Denkmalschutz",
              value: "Zweck_06",
            },
            {
              label: "Erziehung, Volks- und Berufsbildung",
              value: "Zweck_07",
            },
            {
              label: "Naturschutz",
              value: "Zweck_08",
            },
            {
              label: "Wohlfahrtswesen",
              value: "Zweck_09",
            },
            {
              label: "Flüchtlinge, Behindertenhilfe",
              value: "Zweck_10",
            },
            {
              label: "Rettung aus Lebensgefahr",
              value: "Zweck_11",
            },
            {
              label: "Feuer-, Arbeits-, Katastrophen- und Zivilschutz",
              value: "Zweck_12",
            },
            {
              label: "Internationale Gesinnung",
              value: "Zweck_13",
            },
            {
              label: "Tierschutz",
              value: "Zweck_14",
            },
            {
              label: "Entwicklungszusammenarbeit",
              value: "Zweck_15",
            },
            {
              label: "Verbraucherberatung und -schutz",
              value: "Zweck_16",
            },
            {
              label: "Strafgefangene",
              value: "Zweck_17",
            },
            {
              label: "Gleichberechtigung Frauen und Männer",
              value: "Zweck_18",
            },
            {
              label: "Schutz von Ehe und Familie",
              value: "Zweck_19",
            },
            {
              label: "Kriminalprävention",
              value: "Zweck_20",
            },
            {
              label: "Sport",
              value: "Zweck_21",
            },
            {
              label: "Heimatpflege und -kunde",
              value: "Zweck_22",
            },
            {
              label: "Tier- und Pflanzenzucht, Brauchtum",
              value: "Zweck_23",
            },
            {
              label: "Demokratisches Staatswesen",
              value: "Zweck_24",
            },
            {
              label: "Bürgerschaftliches Engagement",
              value: "Zweck_25",
            },
            {
              label: "Mildtätigkeit",
              value: "Zweck_26",
            },
            {
              label: "Kirchliche Zwecke",
              value: "Zweck_27",
            },
          ],
        },
      },
    },
    projectHasStarted: {
      title: "Project has started",
      key: "sp_isstarted",
      icon: "terminal",
      schema: {
        label: "Maßnahme begonnen",
        type: "radio",
        key: "spIsstarted",
        input: true,
        dataType: "string",
        validate: {
          required: false,
        },
        tooltip: "Has the project already begun?",
        description: "Has the project already begun?",
        values: [
          {
            label: "Ja",
            value: "true",
            shortcut: "",
          },
          {
            label: "Nein",
            value: "false",
            shortcut: "",
          },
        ],
      },
    },
    totalFunding: {
      title: "Funding total",
      key: "sp_fund_total",
      icon: "terminal",
      schema: {
        label: "Finanzierung gesamt",
        description: "Total funding ",
        tooltip: "Total funding ",
        mask: false,
        tableView: false,
        delimiter: false,
        requireDecimal: false,
        inputFormat: "plain",
        truncateMultipleSpaces: false,
        validate: {
          required: true,
          min: 10,
          max: 999999999999,
        },
        key: "spFundTotal",
        type: "number",
        input: true,
      },
    },
    totalCost: {
      title: "Costs total",
      key: "sp_cost_total",
      icon: "terminal",
      schema: {
        label: "Projektkosten gesamt",
        description: "Total project costs",
        tooltip: "Total project costs",
        mask: false,
        tableView: false,
        delimiter: false,
        requireDecimal: false,
        inputFormat: "plain",
        truncateMultipleSpaces: false,
        validate: {
          required: true,
          min: 10,
          max: 999999999999,
        },
        key: "spCostTotal",
        type: "number",
        input: true,
      },
    },

    ownFunds: {
      title: "Own funds",
      key: "sp_fund_own",
      icon: "terminal",
      schema: {
        label: "Eigenmittel",
        description: "Own funds",
        tooltip: "Own funds",
        mask: false,
        tableView: false,
        delimiter: false,
        requireDecimal: false,
        inputFormat: "plain",
        truncateMultipleSpaces: false,
        validate: {
          required: true,
          min: 10,
          max: 999999999999,
        },
        key: "spFundOwn",
        type: "number",
        input: true,
      },
    },

    requestedAmount: {
      title: "Requested amount",
      key: "sp_amount",
      icon: "terminal",
      schema: {
        label: "Anfragebetrag",
        description: "Amount requested",
        tooltip: "Amount requested",
        mask: false,
        tableView: false,
        delimiter: false,
        requireDecimal: false,
        inputFormat: "plain",
        truncateMultipleSpaces: false,
        validate: {
          required: true,
          min: 10,
          max: 999999999999,
        },
        key: "spAmount",
        type: "number",
        input: true,
      },
    },

    noticeOfExcemption: {
      title: "Notice of Excemption",
      key: "spFreistellung",
      icon: "fab fa-file",
      schema: {
        label: "Freistellungsbescheid",
        type: "file",
        storage: "base64",
        filePattern: ".jpg, .jpeg, .pdf",
        fileMaxSize: "10MB",
        validate: {
          required: true,
          custom:
            "valid = (data.spFreistellung[0].size <= 5242880) ? true : 'File size exceeds maximum limit of 5MB.';",
        },
        key: "spFreistellung",
        input: true,
        tooltip:
          "The file must be of the type 'jpg', 'jpeg' or 'pdf' and must not be larger than 5 MB.",
        description: "Notice of excemption",
      },
    },
    moreFiles: {
      title: "More files",
      key: "sp_morefiles",
      icon: "fab fa-file",
      schema: {
        label: "Anderes",
        type: "file",
        storage: "base64",
        filePattern: ".jpg, .jpeg, .pdf",
        fileMaxSize: "1MB",
        multiple: true,
        validate: {
          custom:
            "valid = (data.spMorefiles.length <= 5) ? true : 'Maximum number of files is 5';",
        },
        key: "spMorefiles",
        input: true,
        tooltip:
          "You can upload a maximum of 5 files. The files must be of the type 'jpg', 'jpeg' or 'pdf' and must not be larger than 1 MB each.",
        description: "Additional files",
      },
    },
    costAndFinance: {
      title: "Cost and Finance",
      icon: "terminal",
      schema: {
        label: "Kosten und Finanzierung",
        tableView: false,
        key: "spCostFinancePlan",
        type: "container",
        input: false,
        components: [
          {
            label: "HTML",
            content: "<h3><b>Kosten</b></h3>",
            refreshOnChange: false,
            key: "html1",
            type: "htmlelement",
            input: false,
            tableView: false,
          },
          {
            label: "Kosten",
            reorder: false,
            addAnother: " ",
            addAnotherPosition: "bottom",
            layoutFixed: false,
            enableRowGroups: false,
            initEmpty: false,
            customClass: "table-borderless ",
            hideLabel: true,
            tableView: false,
            defaultValue: [
              {
                costType: "",
              },
            ],
            key: "costDataGrid",
            type: "datagrid",
            input: true,
            components: [
              {
                label: "Kostenart",
                placeholder: "Type of cost",
                hideLabel: true,
                tableView: true,
                validate: {
                  required: true,
                },
                key: "costType",
                type: "textfield",
                input: true,
              },
              {
                label: "Betrag",
                placeholder: "Amount",
                hideLabel: true,
                mask: false,
                spellcheck: true,
                tableView: false,
                inputFormat: "plain",
                truncateMultipleSpaces: false,
                key: "amount",
                attributes: {
                  style: "text-align: right;",
                },
                type: "number",
                delimiter: false,
                requireDecimal: false,
                input: true,
              },
            ],
          },
          {
            label: "Projektkosten gesamt",
            labelPosition: "left-left",
            mask: false,
            spellcheck: true,
            disabled: true,
            tableView: false,
            inputFormat: "plain",
            truncateMultipleSpaces: false,
            calculateValue:
              "var GRID_FIELD = 'costDataGrid';\nvar dataGrid = data.spCostFinancePlan.costDataGrid || [];\nvar FIELD1 = 'costType';\nvar FIELD2 = 'amount';\nvar sum = 0; \n\nfor (var i=0; i<=dataGrid.length; i++){\n\tif(!!dataGrid[i] && !!dataGrid[i][FIELD1] && dataGrid[i].hasOwnProperty(FIELD2)){\n\t\tsum = sum + parseFloat(dataGrid[i][FIELD2]);\n\t}\n}\n\nvalue = sum; \n\nvalue = sum; ",
            key: "spCostTotal",
            attributes: {
              style: "text-align: right;",
            },
            type: "number",
            labelWidth: 50,
            labelMargin: 1,
            customClass: "kf-input",
            delimiter: false,
            requireDecimal: false,
            input: true,
          },
          {
            label: "HTML",
            attrs: [
              {
                attr: "",
                value: "",
              },
            ],
            content: "<hr>\n<h3> <b>Finanzierung</b></h3>",
            refreshOnChange: false,
            key: "html",
            type: "htmlelement",
            input: false,
            tableView: false,
          },
          {
            label: "Anfragebetrag",
            labelPosition: "left-left",
            placeholder: "Amount",
            customClass: "font-weight-bold kf-input",
            mask: false,
            spellcheck: true,
            tableView: false,
            inputFormat: "plain",
            truncateMultipleSpaces: false,
            validate: {
              required: true,
            },
            key: "spAmount",
            attributes: {
              style: "text-align: right;",
            },
            type: "number",
            labelWidth: 50,
            labelMargin: 1,
            delimiter: false,
            requireDecimal: false,
            input: true,
          },
          {
            label: "Eigenmittel",
            labelPosition: "left-left",
            placeholder: "Amount",
            mask: false,
            spellcheck: true,
            tableView: false,
            inputFormat: "plain",
            truncateMultipleSpaces: false,
            validate: {
              required: true,
            },
            key: "spFundOwn",
            attributes: {
              style: "text-align: right;",
            },
            type: "number",
            labelWidth: 50,
            labelMargin: 1,
            customClass: "kf-input",
            delimiter: false,
            requireDecimal: false,
            input: true,
          },
          {
            label: "Finanzierung",
            reorder: false,
            addAnother: " ",
            addAnotherPosition: "bottom",
            layoutFixed: false,
            enableRowGroups: false,
            initEmpty: true,
            customClass: "table-borderless ",
            hideLabel: true,
            tableView: false,
            defaultValue: [
              {
                moreFundingType: "",
                status: "beantragt",
              },
            ],
            key: "fundingDataGrid",
            type: "datagrid",
            input: true,
            components: [
              {
                label: "Weitere",
                placeholder: "More",
                hideLabel: true,
                tableView: true,
                validate: {
                  required: true,
                },
                key: "moreFundingType",
                type: "textfield",
                input: true,
              },
              {
                label: "Status",
                widget: "choicesjs",
                hideLabel: true,
                mask: false,
                spellcheck: true,
                tableView: true,
                defaultValue: "beantragt",
                inputFormat: "plain",
                truncateMultipleSpaces: false,
                key: "status",
                attributes: {
                  style: "width: 50px;",
                },
                type: "select",
                data: {
                  values: [
                    {
                      label: "beantragt",
                      value: "beantragt",
                    },
                    {
                      label: "zugesagt",
                      value: "zugesagt",
                    },
                    {
                      label: "vorhanden",
                      value: "vorhanden",
                    },
                  ],
                },
                input: true,
              },
              {
                label: "Betrag",
                placeholder: "Amount",
                hideLabel: true,
                mask: false,
                spellcheck: true,
                tableView: false,
                inputFormat: "plain",
                truncateMultipleSpaces: false,
                key: "moreFundingAmount",
                attributes: {
                  style:
                    "text-align: right;",
                },
                type: "number",
                delimiter: false,
                requireDecimal: false,
                input: true,
              },
            ],
          },
          {
            label: "Summe Finanzierung",
            labelPosition: "left-left",
            mask: false,
            spellcheck: true,
            disabled: true,
            tableView: false,
            inputFormat: "plain",
            truncateMultipleSpaces: false,
            calculateValue:
              "var GRID_FIELD = 'fundingDataGrid';\nvar dataGrid = data.spCostFinancePlan.fundingDataGrid || [];\nvar FIELD1 = 'moreFundingType';\nvar FIELD2 = 'moreFundingAmount';\nvar sum = 0; \n\nfor (var i=0; i<=dataGrid.length; i++){\n\tif(!!dataGrid[i] && !!dataGrid[i][FIELD1] && dataGrid[i].hasOwnProperty(FIELD2)){\n\t\tsum = sum + parseFloat(dataGrid[i][FIELD2]);\n\t}\n}\n\nif (!!data.spCostFinancePlan.spAmount){\n\tsum = sum + parseFloat(data.spCostFinancePlan.spAmount) \n}\n\nif (!!data.spCostFinancePlan.spFundOwn){\n\tsum = sum + parseFloat(data.spCostFinancePlan.spFundOwn)\n}\n\nvalue = sum; ",
            key: "spFundTotal",
            attributes: {
              style: "text-align: right;",
            },
            type: "number",
            labelWidth: 50,
            labelMargin: 1,
            customClass: "kf-input",
            delimiter: false,
            requireDecimal: false,
            input: true,
          },
          {
            label: "Offene Differenz",
            labelPosition: "left-left",
            mask: false,
            spellcheck: true,
            disabled: true,
            tableView: false,
            inputFormat: "plain",
            truncateMultipleSpaces: false,
            calculateValue:
              "var diff = parseFloat(data.spCostFinancePlan.spCostTotal) - parseFloat(data.spCostFinancePlan.spFundTotal);\n\nif (diff<0){\n\tdiff = diff * (-1);\n} \n\nvalue = diff;",
            validate: {
              custom:
                'valid = (data.spCostFinancePlan.spOpenBalance === 0) ? true : "Open balance must be 0"',
            },
            key: "spOpenBalance",
            attributes: {
              style: "text-align: right;",
            },
            type: "number",
            labelWidth: 50,
            labelMargin: 1,
            customClass: "kf-input",
            delimiter: false,
            requireDecimal: false,
            input: true,
          },
        ],
      },
    },
    spDimension: {
      title: "Dimension",
      key: "spDimension",
      icon: "plus-square",
      schema: { 
        key: "spDimension",
        type: "selectboxes",
        label: "Dimension",
        input: true,
      },
    },
  },
};

export const customerSpecificGroup = {
  title: "Customer specific fields",
  default: false,
  weight: 12,
  components: {
    emptyLine: {
      title: "Leerzeile",
      key: "emptyLine",
      icon: "terminal",
      schema: {
        label: "HTML",
        tag: "div",
        attrs: [
          {
            attr: "",
            value: "",
          },
        ],
        content: "<br/>",
        refreshOnChange: false,
        key: "html",
        type: "htmlelement",
        input: false,
        tableView: false,
      },
    },
    content: {
      title: "Content",
      icon: "terminal",
      schema: { weight: 100, type: "content" },
    },
    textfield: true,
    textarea: true,
    checkbox: true,
    select: true,
    number: true,
    datetime: true,
    //selectboxes: true,
  },
};

export const othersGroup = {
  title: "Other",
  default: false,
  weight: 13,
  components: {
    applicationLocation: {
      title: "Application Location",
      key: "sp_appfromwhere",
      icon: "fab fa-map-marker",
      schema: {
        label: "Ort",
        type: "textfield",
        key: "spAppfromwhere",
        input: true,
        validate: {
          maxLength: 100,
          required: false,
        },
        tooltip: "City of your current location",
        description: "City of your current location",
      },
    },
    applicationDate: {
      title: "Application Date",
      key: "sp_appdate",
      icon: "fab fa-calendar",
      schema: {
        label: "Datum",
        type: "datetime",
        enableTime: false,
        format: "dd.MM.yyyy",
        key: "spAppdate",
        input: true,
        validate: {
          required: false,
        },
        tooltip: "Date of application",
        description: "Date of application",
        customClass: "w-25",
      },
    },
    confirm: {
      title: "Confirm",
      key: "sp_appconfirm",
      icon: "fab fa-check-square",
      schema: {
        label: "Bestätigung Richtigkeit",
        type: "checkbox",
        key: "spAppconfirm",
        input: true,
        validate: {
          required: true,
        },
        tooltip: "Confirmation, that all information is correct",
        description: "Confirmation, that all information is correct",
      },
    },
    submitButton: {
      title: "Submit button",
      key: "submit",
      icon: "terminal",
      schema: {
        type: "button",
        label: "Submit",
        key: "submit",
        disableOnInvalid: false,
        input: true,
        tableView: false,
        attributes: {
          style: `background-color: white; border-color:black; color: black;`,
        },
      },
    },
  },
};
